// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container2 {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
  height: 100vh; /* Обеспечивает растяжение основного элемента на всю высоту экрана */
  display: flex;
  flex-direction: column;
  min-height: 100%; /* Минимальная высота для растяжения */
  height: 100%; /* Обеспечивает наследование высоты от main */
}
`, "",{"version":3,"sources":["webpack://./src/components/LoginRegist/LoginRegist.css"],"names":[],"mappings":"AAAA;EACE,SAAO;EACP,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,aAAa,EAAE,oEAAoE;EACnF,aAAa;EACb,sBAAsB;EACtB,gBAAgB,EAAE,sCAAsC;EACxD,YAAY,EAAE,6CAA6C;AAC7D","sourcesContent":[".container2 {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  background-color: #f0f0f0;\n  height: 100vh; /* Обеспечивает растяжение основного элемента на всю высоту экрана */\n  display: flex;\n  flex-direction: column;\n  min-height: 100%; /* Минимальная высота для растяжения */\n  height: 100%; /* Обеспечивает наследование высоты от main */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
