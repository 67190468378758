// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.file-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 2px dashed #77d87a;
  border-radius: 10px;
  background-color: #f9f9f9;
  transition: background-color 0.3s, border-color 0.3s;
  width: 100%;
  max-width: 80%;
  min-height: 200px;
  margin: 30px auto;
}

.file-upload.drag-over {
  background-color: #e8f5e9;
  border-color: #388e3c;
}

.custom-file-upload {
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  color: #ffffff;
  background-color: #4caf50;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.custom-file-upload:hover {
  background-color: #388e3c;
}

.lpc-input[type="file"] {
  display: none;
}

.lpc-button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.lpc-button:hover {
  background-color: #388e3c;
}

.lpc-button:disabled {
  background-color: #c8e6c9;
  cursor: not-allowed;
}
`, "",{"version":3,"sources":["webpack://./src/components/LastPrimeCosts/LastPrimeCosts.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,qBAAqB;EACrB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,0BAA0B;EAC1B,mBAAmB;EACnB,yBAAyB;EACzB,oDAAoD;EACpD,WAAW;EACX,cAAc;EACd,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,eAAe;EACf,cAAc;EACd,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB","sourcesContent":[".custom-file-upload {\n  border: 1px solid #ccc;\n  display: inline-block;\n  padding: 6px 12px;\n  cursor: pointer;\n}\n\n.file-upload {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding: 20px;\n  border: 2px dashed #77d87a;\n  border-radius: 10px;\n  background-color: #f9f9f9;\n  transition: background-color 0.3s, border-color 0.3s;\n  width: 100%;\n  max-width: 80%;\n  min-height: 200px;\n  margin: 30px auto;\n}\n\n.file-upload.drag-over {\n  background-color: #e8f5e9;\n  border-color: #388e3c;\n}\n\n.custom-file-upload {\n  display: inline-block;\n  padding: 10px 20px;\n  cursor: pointer;\n  color: #ffffff;\n  background-color: #4caf50;\n  border: none;\n  border-radius: 5px;\n  margin-bottom: 10px;\n  transition: background-color 0.3s;\n}\n\n.custom-file-upload:hover {\n  background-color: #388e3c;\n}\n\n.lpc-input[type=\"file\"] {\n  display: none;\n}\n\n.lpc-button {\n  padding: 10px 20px;\n  background-color: #4caf50;\n  color: #fff;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n  transition: background-color 0.3s;\n}\n\n.lpc-button:hover {\n  background-color: #388e3c;\n}\n\n.lpc-button:disabled {\n  background-color: #c8e6c9;\n  cursor: not-allowed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
