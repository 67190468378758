import React, { useState } from "react";
import "./LoginForm.css"; // Стили в отдельном CSS файле
import { login } from "../../backApi/backApi"; // Импорт функции API
import { Link, Navigate } from "react-router-dom";
import { useNotification } from "../Notification/NotificationContext";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [navigating, setNavigating] = useState(false);
  const showNotification = useNotification();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null); // Сбрасываем ошибки перед отправкой

    try {
      const response = await login(email, password);
      if (response && response.accessToken) {
        localStorage.setItem("accessToken", response.accessToken);
        localStorage.setItem("email", email);
        showNotification("Авторизация успешна!", "success");
        setNavigating(true);
      } else {
        throw new Error("Некорректный ответ от сервера");
      }
    } catch (err) {
      setError(err.message || "Ошибка при авторизации");
    }
  };

  return navigating ? (
    <Navigate to="/economy" />
  ) : (
    <div className="login-form-container">
      <form className="login-form" onSubmit={handleSubmit}>
        <h2>Авторизация</h2>
        {error && <p className="error-message">{error}</p>}
        <div className="form-group-login">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group-login">
          <label htmlFor="password">Пароль</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="login-buttons-container">
          <button type="submit" className="submit-button-login">
            Войти
          </button>
          <Link to="/registration" className="submit-button-login">
            Регистрация
          </Link>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
