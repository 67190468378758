import React, { useState, useRef, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import * as XLSX from "xlsx";
// Import AG Grid styles
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./Economy.css";
import { useSelector } from "react-redux";
import {
  getActionNamesList,
  getActionUnitsResult,
  getEconomyData,
  getOrganizationsCosts,
  getWarehouses,
  setOrganizationsCosts,
  updateRealisationTypeInDatabase,
  updateIsInActia,
  downloadActionExcelFile,
} from "../../backApi/backApi";
import { useNotification } from "../Notification/NotificationContext";

function Economy() {
  const showNotification = useNotification();

  const inputFields = [
    {
      label: "Отправление мин. FBS, руб",
      name: "otpravlenie_min_fbs",
      type: "number",
    },
    { label: "Приемка по FBS, руб", name: "priemka_po_fbs", type: "number" },
    { label: "Реклама, %", name: "pr_percent", type: "number" },
    { label: "ОР, %", name: "oper_rash_percent", type: "number" },
    {
      label: "Работа Агенства, %",
      name: "agency_work_percent",
      type: "number",
    },
    { label: "Эквайринг, %", name: "ecvairing", type: "number" },
    { label: "Налог, %", name: "nalog", type: "number" },
    { label: "Норма прибыли, %", name: "income_norm_percent", type: "number" },
  ];

  const [warehouseOptions, setWarehouseOptions] = useState([
    "Склад 1",
    "Склад 2",
    "Склад 3",
  ]);

  const [actionsOptions, setActionsOptions] = useState([]);
  const [isActionOptionsVisible, setIsActionOptionsVisible] = useState(false);

  // Объект локализации с русскими переводами
  const localeText = {
    // For Filter Panel
    page: "Страница",
    more: "ещё",
    to: "к",
    of: "из",
    next: "Следующая",
    last: "Последняя",
    first: "Первая",
    previous: "Предыдущая",
    loadingOoo: "Загрузка...",
    pageSizeComp: "Строк на странице",

    // For Set Filter
    selectAll: "Выбрать все",
    searchOoo: "Поиск...",
    blanks: "Пустые",
    blank: "Пустые",
    notBlank: "Не пустые",

    // For Number Filter & Text Filter
    filterOoo: "Фильтр...",
    applyFilter: "Применить фильтр...",
    equals: "Равно",
    notEqual: "Не равно",

    // For Number Filter
    lessThan: "Меньше чем",
    greaterThan: "Больше чем",
    lessThanOrEqual: "Меньше или равно",
    greaterThanOrEqual: "Больше или равно",
    inRange: "В диапазоне",

    // For Text Filter
    contains: "Содержит",
    notContains: "Не содержит",
    startsWith: "Начинается с",
    endsWith: "Заканчивается на",

    // Filter Conditions
    andCondition: "И",
    orCondition: "ИЛИ",

    // Filter Buttons
    applyFilter: "Применить",
    resetFilter: "Сбросить",
    clearFilter: "Очистить фильтр",

    // Sorting
    ascending: "По возрастанию",
    descending: "По убыванию",

    // Добавьте другие необходимые переводы
  };

  const [rowData, setRowData] = useState([{ title: "Загрузка..." }]);

  const defaultColumns = [
    {
      field: "title",
      headerName: "Наименование",
      sortable: true,
      filter: true,
      editable: false,
      pinned: "left",
    },
    {
      field: "vendor_code",
      headerName: "Артикул Поставщика",
      sortable: true,
      filter: true,
      editable: false,
      pinned: "left",
    },
    {
      field: "nmID",
      headerName: "Артикул WB",
      sortable: true,
      filter: true,
      editable: false,
    },
    {
      field: "skus",
      headerName: "Баркоды",
      sortable: true,
      filter: true,
      editable: false,
    },
    {
      field: "brand",
      headerName: "Бренд",
      sortable: true,
      filter: true,
      editable: false,
    },
    {
      field: "imtID",
      headerName: "Группа карточек",
      sortable: true,
      filter: true,
      editable: false,
    },
    {
      field: "subject_name",
      headerName: "Предмет",
      sortable: true,
      filter: true,
      editable: false,
    },
    {
      field: "last_prime_cost",
      headerName: "Себестоимость",
      sortable: true,
      filter: true,
      editable: false,
      cellStyle: (params) => {
        if (params.data.last_prime_cost === null)
          return { backgroundColor: "#FF9473" };
      },
    },
    {
      field: "current_price",
      headerName: "Текущая цена",
      sortable: true,
      filter: true,
      editable: false,
    },
    {
      field: "price_for_income_percent",
      headerName: "Расчетная цена для % прибыли",
      sortable: true,
      filter: true,
      editable: false,
      tooltipValueGetter: (params) => {
        const formulaText =
          params.data.price_for_income_percent_formula &&
          params.data.vendor_code
            ? `Расчет цены для ${params.data.vendor_code}: \n ${params.data.price_for_income_percent_formula}`
            : "Не удалось получить формулу, попробуйте перезагрузить страницу";
        return formulaText;
      },
    },
    {
      field: "realistion_type",
      headerName: "Тип реализации",
      sortable: true,
      filter: true,
      editable: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: { values: ["FBO", "FBS", "DBS", "Экспресс"] },
    },
    {
      field: "volume",
      headerName: "Объем",
      sortable: true,
      filter: true,
      editable: false,
    },
    {
      field: "current_income_for_price_for_income_percent",
      headerName: "Текущая прибыль, руб",
      sortable: true,
      filter: true,
      editable: false,
      tooltipValueGetter: (params) => {
        const formulaText =
          params.data.current_income_formula_for_price_for_income_percent &&
          params.data.vendor_code
            ? `Расчет цены для ${params.data.vendor_code}: \n ${params.data.current_income_formula_for_price_for_income_percent.incomRub.general} \n ${params.data.current_income_formula_for_price_for_income_percent.incomRub.withValues}`
            : "Не удалось получить формулу, попробуйте перезагрузить страницу";
        return formulaText;
      },
    },
    {
      field: "current_income_percent_for_price_for_income_percent",
      headerName: "Текущая прибыль, %",
      sortable: true,
      filter: true,
      editable: false,
      tooltipValueGetter: (params) => {
        const formulaText =
          params.data.current_income_formula_for_price_for_income_percent &&
          params.data.vendor_code
            ? `Расчет цены для ${params.data.vendor_code}: \n ${params.data.current_income_formula_for_price_for_income_percent.incomePercent.general} \n ${params.data.current_income_formula_for_price_for_income_percent.incomePercent.withValues}`
            : "Не удалось получить формулу, попробуйте перезагрузить страницу";
        return formulaText;
      },
    },
    {
      field: "income_for_price_for_income_percent",
      headerName: "Прибыль расчета, руб",
      sortable: true,
      filter: true,
      editable: false,
      tooltipValueGetter: (params) => {
        const formulaText =
          params.data.income_formula_for_price_for_income_percent &&
          params.data.vendor_code
            ? `Расчет цены для ${params.data.vendor_code}: \n ${params.data.income_formula_for_price_for_income_percent.incomRub.general} \n ${params.data.income_formula_for_price_for_income_percent.incomRub.withValues}`
            : "Не удалось получить формулу, попробуйте перезагрузить страницу";
        return formulaText;
      },
    },
    {
      field: "income_percent_for_price_for_income_percent",
      headerName: "Прибыль расчета, %",
      sortable: true,
      filter: true,
      editable: false,
      tooltipValueGetter: (params) => {
        const formulaText =
          params.data.income_formula_for_price_for_income_percent &&
          params.data.vendor_code
            ? `Расчет цены для ${params.data.vendor_code}: \n ${params.data.income_formula_for_price_for_income_percent.incomePercent.general} \n ${params.data.income_formula_for_price_for_income_percent.incomePercent.withValues}`
            : "Не удалось получить формулу, попробуйте перезагрузить страницу";
        return formulaText;
      },
    },
  ];

  const [columnDefs, setColumnDefs] = useState(defaultColumns);
  const [formData, setFormData] = useState(
    inputFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {
      warehouse: warehouseOptions[0],
    })
  );

  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isOrganizationSet, setIsOrganizationSet] = useState(true);
  const [isRealisationTypeChanged, setIsRealisationTypeChanged] =
    useState(false);

  // Создаем ссылку на Grid API
  const gridRef = useRef();

  const currentOrganizationSubscribe = useSelector(
    (state) => state.organization.organization
  );

  const currentOrganization = localStorage.getItem("organization");

  const onExportClick = () => {
    exportToExcel(rowData, "Экономика");
  };

  const exportToExcel = (data, fileName) => {
    // Создаем worksheet из массива объектов
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Генерация файла
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  // Для блока настроек
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Для блока настроек
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await setOrganizationsCosts(formData);
      await getTableData();
    } catch (error) {
      showNotification(`Ошибка при сохранении данных: ${error}`, "error");
    }
  };

  const makeActionsVisible = () => {
    setIsActionOptionsVisible((prev) => !prev);
  };

  const [correntActionName, setCureentActionName] = useState("");
  const [isActionRendered, setIsActionRendered] = useState(false);

  const onActionClick = async (action) => {
    setCureentActionName(action);
    if (!isActionRendered) {
      const newColumnsBefore = [
        {
          field: "is_in_actia",
          minWidth: 50,
          width: 110,
          resizable: true,
          headerName: "В акции",
          sortable: true,
          filter: true,
          editable: true,
          pinned: "left",
          cellRenderer: "agCheckboxCellRenderer",
          cellRendererParams: {
            checkbox: true,
          },
          valueGetter: (params) => {
            // Преобразование null -> false
            return params.data.is_in_actia ?? false;
          },
          valueSetter: (params) => {
            // Обновление значения в данных
            params.data.is_in_actia = params.newValue;
            return true;
          },
        },
      ];
      const newColumnsAfter = [
        {
          field: "action_price",
          headerName: "Акция Цена",
          sortable: true,
          filter: true,
          editable: false,
        },
        {
          field: "income_for_actin_income",
          headerName: "Акция текущая прибыль, руб",
          sortable: true,
          filter: true,
          editable: false,
          tooltipValueGetter: (params) => {
            const formulaText =
              params.data.vendor_code &&
              params.data.income_formula_for_actin_income
                ? `Расчет цены для ${params.data.vendor_code}: \n ${params.data.income_formula_for_actin_income.incomRub.general} \n ${params.data.income_formula_for_actin_income.incomRub.withValues}`
                : "Не удалось получить формулу, попробуйте перезагрузить страницу";
            return formulaText;
          },
        },
        {
          field: "income_percent_for_actin_income",
          headerName: "Акция текущая прибыль, %",
          sortable: true,
          filter: true,
          editable: false,
          tooltipValueGetter: (params) => {
            const formulaText =
              params.data.vendor_code &&
              params.data.income_formula_for_actin_income
                ? `Расчет цены для ${params.data.vendor_code}: \n ${params.data.income_formula_for_actin_income.incomePercent.general} \n ${params.data.income_formula_for_actin_income.incomePercent.withValues}`
                : "Не удалось получить формулу, попробуйте перезагрузить страницу";
            return formulaText;
          },
        },
      ];

      setColumnDefs([...newColumnsBefore, ...columnDefs, ...newColumnsAfter]);
      setIsActionRendered(true);
    }

    const dataArray = await getActionUnitsResult(
      JSON.parse(currentOrganization).organization_uuid,
      action
    );
    setRowData(dataArray);
  };

  const removeActionRender = async () => {
    setIsActionRendered(false);
    setColumnDefs(defaultColumns);
    await getTableData();
  };

  const getTableData = async () => {
    try {
      if (currentOrganization) {
        const tableData = await getEconomyData(
          JSON.parse(currentOrganization).organization_uuid
        );
        setRowData(tableData.data);
        setIsOrganizationSet(true);
      } else {
        setIsOrganizationSet(false);
      }
    } catch (error) {
      showNotification(`Ошибка при загрузке данных таблицы: ${error}`, "error");
    }
  };

  const getWarehousesFromBack = async () => {
    const warehouses = await getWarehouses();
    if (warehouses && warehouses.length > 0) setWarehouseOptions(warehouses);
  };

  const getActionNamesListFromBack = async () => {
    if (currentOrganization) {
      const actionNames = await getActionNamesList(
        JSON.parse(currentOrganization).organization_uuid
      );
      if (actionNames) {
        setActionsOptions(actionNames);
      }
    }
  };

  const getOrganizationsCostsFromBack = async () => {
    if (currentOrganization) {
      const costs = await getOrganizationsCosts(
        JSON.parse(currentOrganization).organization_uuid
      );
      if (costs) {
        setFormData(costs);
      } else {
        setFormData({
          organization_uuid: JSON.parse(currentOrganization).organization_uuid,
        });
      }
      setIsOrganizationSet(true);
    } else {
      setIsOrganizationSet(false);
    }
  };

  const currentActionExport = async () => {
    if (currentOrganization && isActionRendered && correntActionName) {
      try {
        await downloadActionExcelFile(
          correntActionName,
          JSON.parse(currentOrganization).organization_uuid,
          false
        );
        showNotification("Началось скачивание", "info");
      } catch (error) {
        showNotification("Ошибка при формировании Excel", "error");
      }
    } else {
      showNotification("Выберите акцию!", "warning");
    }
  };

  const onCellValueChanged = async (event) => {
    // Проверяем, что изменилось поле 'realistion_type'
    if (event.colDef.field === "realistion_type") {
      const updatedData = {
        nmID: event.data.nmID,
        realisation_type: event.value,
        organization_uuid: event.data.organizationUUID,
      }; // Все данные строки после изменения
      try {
        await updateRealisationTypeInDatabase(updatedData);
        const subscribe = !isRealisationTypeChanged;
        setIsRealisationTypeChanged(subscribe);
      } catch (error) {
        showNotification(
          `Ошибка при обновлении типа реализации: ${error}`,
          "error"
        );
        // При неудаче возвращаем старое значение
        event.node.setDataValue("realistion_type", event.oldValue);
      }
    } else if (event.colDef.field === "is_in_actia") {
      const sendingInfo = {
        nmid: event.data.nmID,
        is_in_actia: event.data.is_in_actia,
        organization_uuid: event.data.organizationUUID,
        action_name: correntActionName,
      };
      try {
        await updateIsInActia(sendingInfo);
      } catch (error) {
        showNotification(
          `Ошибка при добавлении товара в акцию: ${error}`,
          "error"
        );
      }
    }
  };

  useEffect(() => {
    getTableData();
    getWarehousesFromBack();
    getOrganizationsCostsFromBack();
    getActionNamesListFromBack();
  }, [currentOrganizationSubscribe, isRealisationTypeChanged]);

  return isOrganizationSet ? (
    <div className="economy-main">
      <div className=".buttons-container">
        <button
          onClick={() => setIsFormVisible((prev) => !prev)}
          className="toggle-button"
        >
          {isFormVisible ? "Скрыть настройки" : "Настройки"}
        </button>
        <button className="export-button" onClick={onExportClick}>
          Экспорт в Excel
        </button>
        <button className="export-button" onClick={makeActionsVisible}>
          Акции
        </button>
        <button className="export-button" onClick={removeActionRender}>
          Убрать акции
        </button>
        <button className="export-button" onClick={currentActionExport}>
          Экспорт текущей Акции в Excel
        </button>
        {isActionOptionsVisible && actionsOptions ? (
          <ul className="action-options-list">
            {actionsOptions.map((action) => (
              <li
                key={action}
                className="econ-action-option"
                onClick={() => onActionClick(action)}
              >
                {action}
              </li>
            ))}
          </ul>
        ) : null}
      </div>
      {isFormVisible && (
        <form onSubmit={handleSubmit} className="profit-calculator-form">
          {inputFields.map((field) => (
            <div key={field.name} className="form-group">
              <label className="form-label">{field.label}:</label>
              <input
                type={field.type}
                name={field.name}
                value={formData[field.name]}
                onChange={handleInputChange}
                required
                className="form-input"
              />
            </div>
          ))}

          <div className="form-group">
            <label className="form-label">Склад:</label>
            <select
              name="warehouse_name"
              value={formData.warehouse}
              onChange={handleInputChange}
              className="form-select"
            >
              {warehouseOptions ? (
                warehouseOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))
              ) : (
                <option
                  key="Ошибка при получении складов"
                  value="Ошибка при получении складов"
                >
                  Ошибка при получении складов
                </option>
              )}
            </select>
          </div>

          <button type="submit" className="submit-button">
            Сохранить
          </button>
        </form>
      )}

      <div className="ag-theme-alpine">
        <AgGridReact
          //domLayout="autoHeight"
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          localeText={localeText}
          enableCellTextSelection={true}
          onCellValueChanged={onCellValueChanged}
          defaultColDef={{
            flex: 1,
            minWidth: 150,
            resizable: true,
          }}
          pagination={true}
          paginationPageSize={50}
          tooltipShowDelay={0} // Задержка перед показом подсказки
          tooltipHideDelay={99999} // Задержка перед скрытием подсказки
        />
      </div>
    </div>
  ) : (
    <div style={{ margin: "30px" }}>Выберите организацию</div>
  );
}

export default Economy;
