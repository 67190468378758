// Notification.js

import React, { useEffect } from "react";
import "./Notification.css";

function Notification({ message, type = "info", onClose }) {
  useEffect(() => {
    // Автоматически скрыть уведомление через 5 секунд
    const timer = setTimeout(() => {
      onClose();
    }, 5000);

    // Очистить таймер при размонтировании компонента
    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className={`notification notification-${type}`}>
      <span className="notification-message">{message}</span>
      <button className="notification-close" onClick={onClose}>
        ×
      </button>
    </div>
  );
}

export default Notification;
