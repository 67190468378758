import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {
    email: null,
    username: null,
    organization_ids: [],
    //     [{
    //       "organization_uuid": "676884f4-09d9-4b4b-99ed-47f99998c04a",
    //       "organization_name": "org_name",
    //       "owner_email": "email"
    //     },..]
    isAdmin: false,
    organization_ids_own: null,
  },
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = { ...state.user, ...action.payload };
    },
    clearUser(state) {
      state.user = {
        email: null,
        username: null,
        organization_ids: null,
        isAdmin: null,
        organization_ids_own: null,
      };
    },
    updateUsername(state, action) {
      state.user.username = action.payload;
    },
  },
});

export const { setUser, clearUser, updateUsername } = userSlice.actions;

export const userReducer = userSlice.reducer;
