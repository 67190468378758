import React, { useState } from "react";
import "./Registration.css"; // Стили в отдельном CSS файле
import { registration } from "../../backApi/backApi"; // Импорт функции API
import { Navigate } from "react-router-dom";
import { useNotification } from "../Notification/NotificationContext";

const Registration = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [secretKey, setSecretKey] = useState("");
  const [shouldBeredirected, setShouldBeredirected] = useState(false);
  const showNotification = useNotification();

  const handleSubmit = async (e) => {
    e.preventDefault(); //Выключаем дефолтное поведение браузера
    setError(null); // Сбрасываем ошибки перед отправкой

    try {
      const response = await registration(username, email, password, secretKey);
      if (response) {
        showNotification("Регистрация прошла успешно!", "success");
        setShouldBeredirected(true);
      } else {
        throw new Error("Некорректный ответ от сервера");
      }
    } catch (err) {
      setError(err.message || "Ошибка при авторизации");
    }
  };

  return shouldBeredirected ? (
    <Navigate to="/login" />
  ) : (
    <div className="registration-form-container">
      <form className="registration-form" onSubmit={handleSubmit}>
        <h2>Регистрация</h2>
        {error && <p className="error-message">{error}</p>}
        <div className="form-group-registration">
          <label htmlFor="username">Имя</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group-registration">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group-registration">
          <label htmlFor="password">Пароль</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group-registration">
          <label htmlFor="secretKey">Пропуск</label>
          <input
            type="text"
            id="secretKey"
            value={secretKey}
            onChange={(e) => setSecretKey(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="submit-button-registration">
          Зарегистрироваться
        </button>
      </form>
    </div>
  );
};

export default Registration;
