// Body.js

import React from "react";
import "./LoginRegist.css";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

function LoginRegist({ children }) {
  return (
    <main className="container2">
      <Header isMain={false} />
      {children}
      <Footer />
    </main>
  );
}

export default LoginRegist;
