// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header {
  background-color: #00a2ff;
  color: #fff;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10px;
  grid-area: header;
}

.logo {
  all: unset;
  font-size: 24px;
  font-weight: bold;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 15px;
}

.nav-links li {
  display: inline;
}

.nav-links a {
  color: #fff;
  text-decoration: none;
}

.nav-links a:hover {
  text-decoration: underline;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown button {
  background-color: #00a2ff;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.dropdown button:hover {
  background-color: #00a2ff;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.dropdown-content a {
  color: #333;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f5f5f5;
}

.dropdown:hover .dropdown-content {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;EACX,aAAa;EACb,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,SAAS;AACX;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,QAAQ;EACR,sBAAsB;EACtB,gBAAgB;EAChB,yCAAyC;EACzC,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,cAAc;AAChB","sourcesContent":["header {\n  background-color: #00a2ff;\n  color: #fff;\n  padding: 25px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: 10px;\n  grid-area: header;\n}\n\n.logo {\n  all: unset;\n  font-size: 24px;\n  font-weight: bold;\n}\n\n.nav-links {\n  list-style: none;\n  display: flex;\n  gap: 15px;\n}\n\n.nav-links li {\n  display: inline;\n}\n\n.nav-links a {\n  color: #fff;\n  text-decoration: none;\n}\n\n.nav-links a:hover {\n  text-decoration: underline;\n}\n\n.dropdown {\n  position: relative;\n  display: inline-block;\n}\n\n.dropdown button {\n  background-color: #00a2ff;\n  color: #fff;\n  border: none;\n  cursor: pointer;\n  font-size: 16px;\n}\n\n.dropdown button:hover {\n  background-color: #00a2ff;\n}\n\n.dropdown-content {\n  display: none;\n  position: absolute;\n  right: 0;\n  background-color: #fff;\n  min-width: 160px;\n  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);\n  z-index: 1;\n}\n\n.dropdown-content a {\n  color: #333;\n  padding: 12px 16px;\n  text-decoration: none;\n  display: block;\n}\n\n.dropdown-content a:hover {\n  background-color: #f5f5f5;\n}\n\n.dropdown:hover .dropdown-content {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
