import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./Profile.css";
import { deleteUser, updateUserName } from "../../backApi/backApi";
import { updateUsername, clearUser } from "../../slices/userSlice";
import { clearOrganization } from "../../slices/organizationSlice";
import { Navigate } from "react-router-dom";
import { useNotification } from "../Notification/NotificationContext";

function Profile() {
  const showNotification = useNotification();
  const dispatch = useDispatch();
  const me = useSelector((state) => state.user.user);

  const [isEditing, setIsEditing] = useState(false);
  const [newUsername, setNewUsername] = useState(me ? me.username : "");
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  const handleSave = async () => {
    if (!me) return;
    const newUserName = await updateUserName({
      username: newUsername,
      email: me.email,
    });
    dispatch(updateUsername(newUserName));
    setIsEditing(false);
  };

  const deleteProfile = async () => {
    const confirmation = window.confirm(`Вы точно хотите удалить профиль?`);
    if (!confirmation) {
      return;
    }
    try {
      const result = await deleteUser(me.email);
      if (result === 200 || result === "200") {
        logOut();
      } else {
        showNotification("Ошибка при удалении профиля.", "error");
      }
    } catch (error) {
      console.error("Ошибка при удалении профиля:", error);
      showNotification("Произошла ошибка, попробуйте позже.", "error");
    }
  };

  const logOut = () => {
    dispatch(clearOrganization());
    dispatch(clearUser());
    localStorage.clear();
    setIsLoggedOut(true);
  };

  if (isLoggedOut || !me) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="profile-container">
      <div className="profile-item">
        <span>Имя:</span>
        {isEditing ? (
          <>
            <input
              className="profile-input"
              type="text"
              value={newUsername}
              onChange={(e) => setNewUsername(e.target.value)}
            />
            <button className="profile-button" onClick={handleSave}>
              Сохранить
            </button>
            <button
              className="profile-button cancel"
              onClick={() => setIsEditing(false)}
            >
              Отмена
            </button>
          </>
        ) : (
          <>
            <span className="profile-username">{me.username}</span>
            <button
              className="profile-button edit"
              onClick={() => setIsEditing(true)}
            >
              Редактировать
            </button>
          </>
        )}
      </div>
      <div className="profile-item">
        <span>Email:</span>
        <span className="profile-email">{me.email}</span>
      </div>
      <div className="profile-organizations">
        <span className="profile-section-title">Доступные организации:</span>
        {me.organization_ids && me.organization_ids.length > 0 ? (
          me.organization_ids.map((org, index) => (
            <div key={index} className="profile-organization">
              <span className="profile-organization-name">
                {org.organization_name}
              </span>
              <span className="profile-organization-owner">
                {"Владелец - " + org.owner_email}
              </span>
            </div>
          ))
        ) : (
          <div className="profile-no-organizations">
            Нет доступных организаций
          </div>
        )}
      </div>
      <div className="profile-logout-div">
        <button className="profile-logout" onClick={deleteProfile}>
          Удалить профиль
        </button>
        <button className="profile-logout" onClick={logOut}>
          Выйти
        </button>
      </div>
    </div>
  );
}

export default Profile;
