// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Notification.css */

.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #fff;
  color: #333;
  padding: 15px 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  min-width: 250px;
  z-index: 1000;
}

.notification-message {
  flex: 1 1;
}

.notification-close {
  background: none;
  border: none;
  font-size: 20px;
  color: #888;
  cursor: pointer;
  padding: 0;
  margin-left: 15px;
}

.notification-info {
  border-left: 5px solid #3498db;
}

.notification-success {
  border-left: 5px solid #2ecc71;
}

.notification-warning {
  border-left: 5px solid #f1c40f;
}

.notification-error {
  border-left: 5px solid #e74c3c;
}

.notification-close:hover {
  color: #333;
}
`, "",{"version":3,"sources":["webpack://./src/components/Notification/Notification.css"],"names":[],"mappings":"AAAA,qBAAqB;;AAErB;EACE,eAAe;EACf,SAAS;EACT,WAAW;EACX,sBAAsB;EACtB,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,uCAAuC;EACvC,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,WAAW;EACX,eAAe;EACf,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,WAAW;AACb","sourcesContent":["/* Notification.css */\n\n.notification {\n  position: fixed;\n  top: 20px;\n  right: 20px;\n  background-color: #fff;\n  color: #333;\n  padding: 15px 20px;\n  border-radius: 5px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  display: flex;\n  align-items: center;\n  min-width: 250px;\n  z-index: 1000;\n}\n\n.notification-message {\n  flex: 1;\n}\n\n.notification-close {\n  background: none;\n  border: none;\n  font-size: 20px;\n  color: #888;\n  cursor: pointer;\n  padding: 0;\n  margin-left: 15px;\n}\n\n.notification-info {\n  border-left: 5px solid #3498db;\n}\n\n.notification-success {\n  border-left: 5px solid #2ecc71;\n}\n\n.notification-warning {\n  border-left: 5px solid #f1c40f;\n}\n\n.notification-error {\n  border-left: 5px solid #e74c3c;\n}\n\n.notification-close:hover {\n  color: #333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
