import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  organization: {
    organization_name: null,
    organization_uuid: null,
    owner_email: null,
  },
};
const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    setOrganization(state, action) {
      state.organization = { ...state.organization, ...action.payload };
      localStorage.setItem("organization", JSON.stringify(state.organization));
    },
    clearOrganization(state) {
      state.organization = {
        organization_name: null,
        organization_uuid: null,
        owner_email: null,
      };
    },
  },
});

export const { setOrganization, clearOrganization } = organizationSlice.actions;

export const organizationReducer = organizationSlice.reducer;
