import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { checkAuth } from "../../backApi/backApi";

const ProtectedRoute = () => {
  const [auth, setAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    const fetchAuthStatus = async () => {
      if (!token) {
        setLoading(false);
        setAuth(false);
        return;
      }

      try {
        const response = await checkAuth(token);
        setAuth(response);
      } catch (err) {
        console.error("Authentication error:", err);
        setError("Failed to authenticate. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchAuthStatus();
  }, [token]);

  if (loading) {
    return <div>Loading...</div>; // Можно заменить на компонент загрузки
  }

  if (error) {
    return (
      <div>
        <p>{error}</p>
        <Navigate to="/login" />
      </div>
    );
  }

  return auth ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
