// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: grid;
  background-color: #f0f0f0;
  height: 100vh; /* Обеспечивает наследование высоты от main */
  grid-template-rows: auto 1fr auto;
  grid-template-areas: "header" "main" "footer";
}

.mainbody {
  grid-area: main;
  flex-grow: 1; /* Заставляет mainbody занимать оставшееся пространство */
  display: flex; /* Можно добавить, если внутри mainbody будут вложенные элементы */
  align-items: flex-start; /* Опционально для вертикального центрирования контента */
  justify-content: center; /* Опционально для горизонтального центрирования контента */
  overflow: auto;
  scrollbar-width: thin;
}
`, "",{"version":3,"sources":["webpack://./src/components/Body/Body.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,aAAa,EAAE,6CAA6C;EAC5D,iCAAiC;EACjC,6CAA6C;AAC/C;;AAEA;EACE,eAAe;EACf,YAAY,EAAE,yDAAyD;EACvE,aAAa,EAAE,kEAAkE;EACjF,uBAAuB,EAAE,yDAAyD;EAClF,uBAAuB,EAAE,2DAA2D;EACpF,cAAc;EACd,qBAAqB;AACvB","sourcesContent":[".container {\n  display: grid;\n  background-color: #f0f0f0;\n  height: 100vh; /* Обеспечивает наследование высоты от main */\n  grid-template-rows: auto 1fr auto;\n  grid-template-areas: \"header\" \"main\" \"footer\";\n}\n\n.mainbody {\n  grid-area: main;\n  flex-grow: 1; /* Заставляет mainbody занимать оставшееся пространство */\n  display: flex; /* Можно добавить, если внутри mainbody будут вложенные элементы */\n  align-items: flex-start; /* Опционально для вертикального центрирования контента */\n  justify-content: center; /* Опционально для горизонтального центрирования контента */\n  overflow: auto;\n  scrollbar-width: thin;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
