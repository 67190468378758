import axios from "axios";

// Базовый экземпляр Axios
const apiClient = axios.create({
  baseURL: "https://spaceunits.ru/api/v1",
});

const accessToken = localStorage.getItem("accessToken");

export const sendExcelFile = async (file, organization_uuid) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("organization_uuid", organization_uuid);
    const response = await apiClient.post(`/uploadLPC`, formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    console.log("Server responce", response.data);
    return 200;
  } catch (error) {
    console.error(
      "En error during file upload",
      error.response?.data || error.message
    );

    return 500;
  }
};

export const sendExcelActionsFile = async (file, organization_uuid) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("organization_uuid", organization_uuid);
    const response = await apiClient.post(`/uploadActions`, formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    console.log("Server responce", response.data);
    return 200;
  } catch (error) {
    console.error(
      "En error during file upload",
      error.response?.data || error.message
    );

    return 500;
  }
};

export const login = async (email, password) => {
  try {
    // Отправка POST-запроса для авторизации
    const response = await apiClient.post("/login", { email, password });

    if (response.data && response.data.accessToken) {
      return response.data;
    } else {
      throw new Error("Некорректный ответ от сервера");
    }
  } catch (error) {
    // Обработка ошибок
    throw new Error(
      error.response?.data?.message || String(error).includes("400")
        ? "Не верный Email или пароль"
        : "Ошибка подключения к серверу"
    );
  }
};

export const registration = async (username, email, password, secretKey) => {
  try {
    // Отправка POST-запроса для авторизации
    const response = await apiClient.post("/register", {
      username,
      email,
      password,
      secretKey,
    });

    if (response.status === 200) {
      return true;
    } else {
      throw new Error("Некорректный ответ от сервера");
    }
  } catch (error) {
    // Обработка ошибок
    throw new Error(
      error.response?.data?.error || "Ошибка подключения к серверу"
    );
  }
};

export const checkAuth = async (token) => {
  try {
    const response = await apiClient.get("/validateToken", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response && response.status === 200) {
      const { valid } = response.data; // Предположим, что API возвращает объект { valid: true }
      return valid || false; // Убедитесь, что возвращаете явное значение
    } else {
      console.error("Unexpected status code:", response.status);
      return false;
    }
  } catch (error) {
    console.error("Error during token validation:", error.message);
    return false;
  }
};

export const getMe = async (email) => {
  if (accessToken) {
    try {
      const response = await apiClient.get(`/getMe?email=${email}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response && response.status === 200) {
        const { user } = response.data[0];
        return user;
      } else {
        console.error("Unexpected status code:", response.status);
        return false;
      }
    } catch (error) {
      console.error("Error during getMe:", error.message);
      return false;
    }
  }
  return;
};

export const getEconomyData = async (org_uuid) => {
  try {
    const response = await apiClient.post(
      "/getUnitsResult",
      {
        organization_uuid: org_uuid,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response && response.status === 200) {
      return response;
    } else {
      console.log("Некорректный ответ от сервера");
    }
  } catch (error) {
    console.log("Ошибка подключения к серверу");
  }
};

export const getWarehouses = async () => {
  try {
    const response = await apiClient.get("/getWarehouses", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response && response.status === 200) {
      return response.data;
    } else {
      console.log("Некорректный ответ от сервера");
    }
  } catch (error) {
    console.log("Ошибка подключения к серверу");
  }
};

export const getOrganizationsCosts = async (org_uuid) => {
  try {
    const response = await apiClient.post(
      "/getOrganizationsCosts",
      {
        organization_uuid: org_uuid,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response && response.status === 200) {
      return response.data[0];
    } else {
      console.log("Некорректный ответ от сервера");
    }
  } catch (error) {
    console.log("Ошибка подключения к серверу");
  }
};

export const setOrganizationsCosts = async (data) => {
  try {
    const response = await apiClient.post("/setOrganizationsCosts", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response && response.status === 200) {
      return response.data[0];
    } else {
      console.log("Некорректный ответ от сервера");
    }
  } catch (error) {
    console.log("Ошибка подключения к серверу");
  }
};

export const createOrganization = async (data) => {
  try {
    const response = await apiClient.post("/registrationOrganization", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      return response.status;
    } else {
      console.log("Некорректный ответ от сервера");
    }
  } catch (error) {
    console.log("Ошибка подключения к серверу");
  }
};

export const getUsersByOrganization = async (org_uuid) => {
  try {
    const response = await apiClient.post(
      "/getUsersByOrganization",
      {
        organization_uuid: org_uuid,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response && response.status === 200) {
      return response.data;
    } else {
      console.log("Некорректный ответ от сервера");
    }
  } catch (error) {
    console.log("Ошибка подключения к серверу");
  }
};

export const addUserToOrganization = async (userInfo) => {
  try {
    const response = await apiClient.post(
      "/updateOrganizationForUser",
      userInfo,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.status === 200) {
      return response.status;
    } else {
      console.log("Некорректный ответ от сервера");
    }
  } catch (error) {
    // Обработка ошибок
    console.log("Ошибка подключения к серверу");
  }
};

export const updateUserName = async (userInfo) => {
  try {
    const response = await apiClient.post("/updateUserName", userInfo, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response.status === 200) {
      return response.data.newUserName;
    } else {
      console.log("Некорректный ответ от сервера");
    }
  } catch (error) {}
};

export const deleteOrganization = async (userInfo) => {
  try {
    const response = await apiClient.post("/deleteOrganization", userInfo, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response.status === 200) {
      return response.status;
    } else {
      console.log("Некорректный ответ от сервера");
    }
  } catch (error) {}
};

//{ email, organization_uuid, currentUserEmail }
export const removeUserFromOrganization = async (userInfo) => {
  try {
    const response = await apiClient.post(
      "/removeUserFromOrganization",
      userInfo,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.status === 200) {
      return response.status;
    } else {
      console.log("Некорректный ответ от сервера");
    }
  } catch (error) {}
};

export const deleteUser = async (email) => {
  try {
    const response = await apiClient.post(
      "/deleteUser",
      { email: email },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.status === 200) {
      return response.status;
    } else {
      console.log("Некорректный ответ от сервера");
    }
  } catch (error) {}
};

//{ nmID, realistion_type, organization_uuid }
export const updateRealisationTypeInDatabase = async (userInfo) => {
  try {
    const response = await apiClient.post(
      "/updateRealisationTypeInDatabase",
      userInfo,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.status === 200) {
      return response.status;
    } else {
      console.log("Некорректный ответ от сервера");
    }
  } catch (error) {}
};

export const getActionNamesList = async (org_uuid) => {
  try {
    const response = await apiClient.post(
      "/getActionNamesList",
      { organization_uuid: org_uuid },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      console.log("Некорректный ответ от сервера");
    }
  } catch (error) {
    console.log(error);
  }
};

export const getActionUnitsResult = async (org_uuid, action_name) => {
  try {
    const response = await apiClient.post(
      "/getActionUnitsResult",
      { organization_uuid: org_uuid, action_name: action_name },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      console.log("Некорректный ответ от сервера");
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateIsInActia = async (sendingInfo) => {
  try {
    const response = await apiClient.post("/updateIsInActia", sendingInfo, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      console.log("Некорректный ответ от сервера");
    }
  } catch (error) {
    console.log(error);
  }
};

// Функция для загрузки файла
export async function downloadActionExcelFile(
  action_name,
  organization_uuid,
  should_be_saved
) {
  try {
    // Выполняем запрос на сервер
    const response = await apiClient.post(
      "/generateExcelAction",
      {
        action_name: action_name,
        organization_uuid: organization_uuid,
        should_be_saved: should_be_saved,
      },

      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: "blob", // Указываем, что ответ будет в виде бинарных данных
      }
    );

    // Создаем ссылку для скачивания
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;

    // Указываем имя файла
    const contentDisposition = response.headers["content-disposition"];
    let fileName = "default.xlsx"; // Значение по умолчанию, если имя файла не передано
    if (contentDisposition) {
      // Попытка найти имя файла в формате filename*=UTF-8''
      const utf8Match = contentDisposition.match(/filename\*=UTF-8''(.+)/);
      if (utf8Match && utf8Match[1]) {
        // Декодируем закодированное имя файла
        fileName = decodeURIComponent(utf8Match[1]);
      } else {
        // Попытка найти имя файла в формате filename="..."
        const regularMatch = contentDisposition.match(/filename="(.+)"/);
        if (regularMatch && regularMatch[1]) {
          fileName = regularMatch[1];
        }
      }
    }

    link.setAttribute("download", fileName);
    // Добавляем ссылку в DOM, кликаем по ней и удаляем её
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // Освобождаем объект URL
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error downloading the file:", error);
    throw new Error("Ошибка при загрузке файла");
  }
}
