// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --the-buttons-color: #007bff;
  --the-buttons-color-on-hover: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/styles/variables.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,qCAAqC;AACvC","sourcesContent":[":root {\n  --the-buttons-color: #007bff;\n  --the-buttons-color-on-hover: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
