// Body.js

import React, { useEffect } from "react";
import "./Body.css";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { useDispatch } from "react-redux";
import { setUser } from "../../slices/userSlice";
import { getMe } from "../../backApi/backApi";
import { useSelector } from "react-redux";

// {
//   "email": "valo21313@gmail.com",
//   "username": "adminvo1233",
//   "isAdmin": true,
//   "organization_ids": [
//     {
//       "organization_uuid": "676884f4-09d9-4b4b-99ed-47f99998c04a",
//       "organization_name": "org_name",
//       "owner_email": "email"
//     },
//     {
//       "organization_uuid": "c037c7c4-d0ff-4509-898d-65687b9f1dc9",
//       "organization_name": "org_name2",
//       "owner_email": "email2"
//     }
//   ],
//   "organization_ids_own": "{676884f4-09d9-4b4b-99ed-47f99998c04a,c037c7c4-d0ff-4509-898d-65687b9f1dc9}"
// }

function Body({ children }) {
  const emailFromLockalStorage = localStorage.getItem("email");
  const user = useSelector((state) => state.user.user);

  const dispatch = useDispatch();

  const handleWhoIsMe = async () => {
    const userData = await getMe(String(emailFromLockalStorage));
    dispatch(setUser(userData));
  };

  useEffect(() => {
    if (user.email === null) handleWhoIsMe();
  }, []);

  return (
    <main className="container">
      <Header isMain={true} />
      <div className="mainbody">{children}</div>
      <Footer />
    </main>
  );
}

export default Body;
