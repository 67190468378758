// Header.js

import React, { useEffect, useState } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setOrganization } from "../../slices/organizationSlice";

function Header({ isMain }) {
  const [organizationSelecter, setOrganizationSelecter] =
    useState("Организация ▼");

  const organizationsList =
    useSelector((state) => state.user.user.organization_ids) || []; // Устанавливаем пустой массив по умолчанию

  const dispatch = useDispatch();

  const handleCurrentOrganization = (org) => {
    if (org) {
      dispatch(setOrganization(org));
      setOrganizationSelecter(org.organization_name + " ▼");
    } else {
      setOrganizationSelecter("Нет доступных организаций ▼");
    }
  };

  useEffect(() => {
    const org_name_from_storage = localStorage.getItem("organization");
    if (org_name_from_storage) {
      const org = JSON.parse(org_name_from_storage);
      handleCurrentOrganization(org);
    } else if (organizationsList.length > 0) {
      handleCurrentOrganization(organizationsList[0]);
    } else {
      // Если организаций нет, устанавливаем соответствующее сообщение
      setOrganizationSelecter("Нет доступных организаций ▼");
    }
  }, [organizationsList]);

  return isMain ? (
    <header>
      <Link to="/" className="logo">
        SPACEUNITS
      </Link>
      <nav>
        <ul className="nav-links">
          <li>
            <Link to="/economy">Экономика</Link>
          </li>
          <li>
            <Link to="/last_prime_costs">Загрузить себестоимости</Link>
          </li>
          <li>
            <Link to="/actions">Загрузить акции</Link>
          </li>
          <li>
            <Link to="/organization">Организации</Link>
          </li>
          <li>
            <Link to="/profile"> Профиль</Link>
          </li>
        </ul>
      </nav>
      <div className="dropdown">
        <button>{organizationSelecter}</button>
        <div className="dropdown-content">
          {organizationsList.length > 0 ? (
            organizationsList.map((org) => (
              <a
                key={org.organization_uuid}
                href="#"
                onClick={() => handleCurrentOrganization(org)}
              >
                {org.organization_name}
              </a>
            ))
          ) : (
            <span>Нет доступных организаций</span>
          )}
        </div>
      </div>
    </header>
  ) : (
    <header>
      <Link to="/economy" className="logo">
        Эконом
      </Link>
    </header>
  );
}

export default Header;
