import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  createOrganization,
  getUsersByOrganization,
  addUserToOrganization,
  deleteOrganization,
  removeUserFromOrganization, // Предположим, у вас есть эта функция
} from "../../backApi/backApi";
import "./Organization.css";

function Organization() {
  const [myOrganizations, setMyOrganizations] = useState([]);
  const [newOrganizationName, setNewOrganizationName] = useState("");
  const [newOrganizationAPIKey, setNewOrganizationAPIKey] = useState("");
  const [newOrgWasCreate, setNewOrgWasCreate] = useState(false);
  const [organizationMembers, setOrganizationMembers] = useState({});
  const [openOrganizations, setOpenOrganizations] = useState({});
  const [newMemberEmails, setNewMemberEmails] = useState({});

  const organizationsList = useSelector(
    (state) => state.user.user.organization_ids
  );
  const currentUserEmail = useSelector((state) => state.user.user.email);

  const createNewOrganization = async () => {
    const result = await createOrganization({
      email: currentUserEmail,
      organization_name: newOrganizationName,
      wbapikey: newOrganizationAPIKey,
    });
    if (result === 200 || result === "200") {
      alert("Организация была создана");
      setNewOrgWasCreate(!newOrgWasCreate);
    } else {
      alert("Произошла ошибка, попробуйте позже!");
    }
  };

  const getUsersByOrganizationInternal = async (uuid) => {
    const result = await getUsersByOrganization(uuid);
    setOrganizationMembers((prev) => ({
      ...prev,
      [uuid]: result,
    }));
  };

  const toggleOrganization = async (uuid) => {
    setOpenOrganizations((prev) => ({
      ...prev,
      [uuid]: !prev[uuid],
    }));

    if (!openOrganizations[uuid]) {
      await getUsersByOrganizationInternal(uuid);
    }
  };

  const handleNewMemberEmailChange = (uuid, email) => {
    setNewMemberEmails((prev) => ({
      ...prev,
      [uuid]: email,
    }));
  };

  const handleAddMember = async (e, uuid) => {
    e.preventDefault();
    const email = newMemberEmails[uuid];
    if (!email) {
      alert("Введите email нового участника.");
      return;
    }
    try {
      const result = await addUserToOrganization({
        email,
        organization_uuid: uuid,
        currentUserEmail,
      });
      if (result === 200 || result === "200") {
        await getUsersByOrganizationInternal(uuid);
        alert("Пользователь успешно добавлен.");
      } else {
        alert("Ошибка при добавлении пользователя.");
      }
    } catch (error) {
      console.error("Ошибка при добавлении пользователя:", error);
      alert("Произошла ошибка, попробуйте позже.");
    }
  };

  const handleDeleteOrganization = async (uuid, organizationName) => {
    const confirmation = window.confirm(
      `Вы точно хотите удалить организацию "${organizationName}"?`
    );
    if (!confirmation) {
      return;
    }
    try {
      const result = await deleteOrganization({
        organization_uuid: uuid,
        currentUserEmail: currentUserEmail,
      });
      if (result === 200 || result === "200") {
        alert("Организация успешно удалена.");
        setNewOrgWasCreate(!newOrgWasCreate);
      } else {
        alert("Ошибка при удалении организации.");
      }
    } catch (error) {
      console.error("Ошибка при удалении организации:", error);
      alert("Произошла ошибка, попробуйте позже.");
    }
  };

  const handleRemoveUser = async (e, uuid, memberEmail, organizationName) => {
    const confirmation = window.confirm(
      `Вы точно хотите удалить пользоватлея "${memberEmail}" из "${organizationName}"?`
    );
    if (!confirmation) {
      return;
    }
    try {
      const result = await removeUserFromOrganization({
        email: memberEmail,
        organization_uuid: uuid,
        currentUserEmail: currentUserEmail,
      });
      if (result === 200 || result === "200") {
        alert("Пользователь успешно удален.");
        setNewOrgWasCreate(!newOrgWasCreate);
      } else {
        alert("Ошибка при удалении пользователя.");
      }
    } catch (error) {
      console.error("Ошибка при удалении пользователя:", error);
      alert("Произошла ошибка, попробуйте позже.");
    }
  };

  useEffect(() => {
    setMyOrganizations(organizationsList);
  }, [organizationsList, newOrgWasCreate]);

  return (
    <div className="org-container">
      {myOrganizations && myOrganizations.length > 0 ? (
        <div className="org-organization-list">
          {myOrganizations.map((org) => {
            const isOpen = openOrganizations[org.organization_uuid];
            return (
              <div
                key={org.organization_uuid}
                className="org-organization-item"
              >
                <div onClick={() => toggleOrganization(org.organization_uuid)}>
                  <span className="org-organization-name">
                    {org.organization_name}
                  </span>
                  <span className="org-owner-email">
                    {" "}
                    Владелец - {org.owner_email}
                  </span>
                </div>
                {isOpen && (
                  <div className="org-members-list">
                    <div className="org-members">
                      {organizationMembers[org.organization_uuid] &&
                        organizationMembers[org.organization_uuid].map(
                          (member, index) => (
                            <div key={index} className="org-member-item">
                              <span>{member.username}</span> ({member.email}){" "}
                              <button
                                className="org-remove-member-button"
                                onClick={(e) => {
                                  handleRemoveUser(
                                    e,
                                    org.organization_uuid,
                                    member.email,
                                    org.organization_name
                                  );
                                }}
                              >
                                Х
                              </button>
                            </div>
                          )
                        )}
                    </div>
                    <form
                      className="org-add-member-form"
                      onSubmit={(e) =>
                        handleAddMember(e, org.organization_uuid)
                      }
                    >
                      <label>Новый участник</label>
                      <input
                        type="email"
                        placeholder="Email участника"
                        value={newMemberEmails[org.organization_uuid] || ""}
                        onChange={(e) =>
                          handleNewMemberEmailChange(
                            org.organization_uuid,
                            e.target.value
                          )
                        }
                      />
                      <button type="submit">Добавить</button>
                    </form>
                    <button
                      className="org-remove-button"
                      type="button"
                      onClick={() =>
                        handleDeleteOrganization(
                          org.organization_uuid,
                          org.organization_name
                        )
                      }
                    >
                      Удалить организацию
                    </button>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="org-no-organizations">Нет доступных организаций</div>
      )}
      <form>
        <label className="org-form-label">Новая организация</label>
        <input
          type="text"
          placeholder="Название организации"
          id="organization_name"
          className="org-input"
          onInput={(event) => {
            setNewOrganizationName(event.target.value);
            console.log(event.target.value);
          }}
        />
        <input
          type="text"
          placeholder="API ключ"
          id="api_key"
          className="org-input"
          onInput={(event) => {
            setNewOrganizationAPIKey(event.target.value);
            console.log(event.target.value);
          }}
        />
        <button
          type="submit"
          className="org-submit-button"
          onClick={createNewOrganization}
        >
          Создать
        </button>
      </form>
    </div>
  );
}

export default Organization;
