import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { store } from "./store";
import { Provider } from "react-redux";
import { NotificationProvider } from "./components/Notification/NotificationContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <NotificationProvider>
        <App></App>
      </NotificationProvider>
    </Provider>
  </React.StrictMode>
);
