import React, { useState } from "react";
import "./LastPrimeCosts.css";
import { sendExcelFile } from "../../backApi/backApi";
import { useSelector } from "react-redux";
import { useNotification } from "../Notification/NotificationContext";

function LastPrimeCosts() {
  const showNotification = useNotification();
  const [file, setFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  const currentOrganizationSubscribe = useSelector(
    (state) => state.organization.organization
  );
  let organization_uuid = "";
  try {
    organization_uuid = currentOrganizationSubscribe.organization_uuid;
  } catch (error) {
    console.error(error);
  }

  const handleDrop = (event) => {
    event.preventDefault(); // Отмена стандартного поведения браузера
    event.stopPropagation(); // Остановка всплытия события

    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles && droppedFiles.length > 0) {
      setFile(droppedFiles[0]); // Установка первого перетянутого файла
      event.dataTransfer.clearData(); // Очистка данных дропа
    }
    setIsDragging(false); // Убираем "подсветку" области
  };

  const handleDragOver = (event) => {
    event.preventDefault(); // Отмена стандартного поведения браузера
    setIsDragging(true); // Включаем "подсветку" области
  };

  const handleDragLeave = (event) => {
    event.preventDefault(); // Отмена стандартного поведения
    setIsDragging(false); // Убираем "подсветку" области
  };

  const handleUpload = async () => {
    if (file) {
      const status = await sendExcelFile(file, organization_uuid);
      if (status === 200) {
        showNotification("Файл успешно загружен!", "success");
      } else {
        showNotification("Ошибка при загрузке файла.", "error");
      }
    } else {
      showNotification("Пожалуйста, выберите файл для загрузки.", "warning");
    }
  };

  return (
    <div
      className={`file-upload ${isDragging ? "drag-over" : ""}`}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
      <label htmlFor="file-upload" className="custom-file-upload">
        {file ? file.name : "Перетащите файл или нажмите для выбора"}
      </label>
      <input
        className="lpc-input"
        id="file-upload"
        type="file"
        accept=".xlsx, .xls"
        onChange={(e) => setFile(e.target.files[0])}
      />
      <button className="lpc-button" onClick={handleUpload} disabled={!file}>
        Загрузить
      </button>
    </div>
  );
}

export default LastPrimeCosts;
