// App.js

import React from "react";
import Body from "./components/Body/Body";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./styles/styles.css";
import LoginForm from "./components/Login/LoginForm";
import Registration from "./components/Registrarion/Registration";
import LastPrimeCosts from "./components/LastPrimeCosts/LastPrimeCosts";
import LoginRegist from "./components/LoginRegist/LoginRegist";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import Economy from "./components/Economy/Economy";
import Organization from "./components/Organization/Organization";
import Profile from "./components/Profile/Profile";
import Actions from "./components/Actions/Actions";

function App() {
  return (
    <div className="app-container">
      <Router>
        <Routes>
          <Route
            path="/login"
            element={
              <LoginRegist>
                <LoginForm />
              </LoginRegist>
            }
          />
          <Route
            path="/registration"
            element={
              <LoginRegist>
                <Registration />
              </LoginRegist>
            }
          />
          <Route element={<ProtectedRoute />}>
            <Route
              path="/last_prime_costs"
              element={
                <Body>
                  <LastPrimeCosts />
                </Body>
              }
            />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route
              path="/economy"
              element={
                <Body>
                  <Economy />
                </Body>
              }
            />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route
              path="/"
              element={
                <Body>
                  <Economy />
                </Body>
              }
            />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route
              path="/organization"
              element={
                <Body>
                  <Organization />
                </Body>
              }
            />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route
              path="/profile"
              element={
                <Body>
                  <Profile />
                </Body>
              }
            />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route
              path="/actions"
              element={
                <Body>
                  <Actions />
                </Body>
              }
            />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
